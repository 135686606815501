/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .auth .login-btn {
    /* width: 150px;
        height: 40px; */
    font-size: 18px;
    position: relative;
    padding: 5px 20px 5px 20px;
    /* left: 10px; */
  }

  .auth .login-code {
    /* width: 286px;
        height: 54px; */
    padding: 5px 20px 5px 20px;
    font-size: 18px;
  }

  .auth .login-label {
    margin-top: 35px;
    font-size: 25px;
  }

  .auth .text-size {
    font-size: 16px;
  }

  .custom-control-label::after,
  .custom-control-label::before {
    width: 18px !important;
    height: 18px !important;
  }

  .custom-control-label {
    padding-left: 5px;
    padding-top: 3px;
    font-size: 16px;
  }

  .box {
    min-height: 290px !important;
  }

  .header .header-text {
    font-size: 17px !important;
  }

  .header .header-time-text {
    font-size: 14px !important;
  }

  .category .sub-text {
    font-size: 20px !important;
  }

  .category .over {
    font-size: 12px !important;
    line-height: 35px !important;
    height: 50px !important;
  }

  .category #example-video {
    width: 280px !important;
    height: 300px !important;
  }

  .error-msg {
    font-size: 14px !important;
  }

  .info-img {
    max-width: 340px !important;
    min-width: 265px !important;
    max-height: 270px !important;
    min-height: 255px !important;
  }

  .img-contain {
    max-width: 340px !important;
    min-width: 265px !important;
    max-height: 270px !important;
    min-height: 255px !important;
  }

  .infotitle {
    font-size: 30px !important;
  }

  .category {
    font-size: 13px !important;
  }

  .content-text {
    font-size: 15px !important;
  }

  .button-info {
    border-radius: 8px;
    font-size: 14px !important;
  }

  .category-box {
    max-height: 300px !important;
    overflow-y: auto !important;
  }

  .channelbox {
    max-height: 300px !important;
    overflow-y: auto !important;
  }

  /* .imageContainer-movie {
    width: 120px !important;
    height: 152px !important;
  }

  .movie-img {
    max-width: 120px !important;
    max-height: 155px !important;
    min-width: 100px !important;
    min-height: 80px !important;
  }

  .imageContainer-series {
    width: 130px !important;
    height: 145px !important;
  }

  .series-img {
    max-width: 130px !important;
    max-height: 150px !important;
    min-width: 120px !important;
    min-height: 145px !important;
  } */

  .search-img {
    max-height: 125px !important;
    min-height: 120px !important;
  }
  .search-imgseries{
    max-height: 125px !important;
    min-height: 120px !important;
  }
  .fav-icon {   
    margin-right: 22px !important;    
  }
  .fav-icon1{
    margin-right: 15px !important;   
  }

  .movie .title {
    font-size: 13px !important;
    text-align: left !important;
  }

  .episode-title {
    font-size: 13px !important;
  }

  .series-header {
    font-size: 20px !important;
  }

  .sidebar {
    width: 56px !important;
  }

  .sidebar ul li {
    margin: 12px 0px !important;
  }

  .sidebar ul li a i {
    min-width: 55px !important;
  }

  #page-content-wrapper {
    width: calc(100vw - 56px) !important;
    margin-left: 56px;
  }

  /* .carousal-img {
    min-width: 100px !important;
    max-width: 100px !important;
    min-height: 120px !important;
    max-height: 125px !important;
  } */

  #wrapper.full #page-content-wrapper {
    margin-left: 56px !important;
  }

  .epg-title {
    font-size: 16px !important;
  }

 .header-label{
   font-size: 25px!important;
 }
 .field-label{
  font-size: 16px !important; 
 }
 .field-value{
  font-size: 14px !important; 
 }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .box {
    min-height: 470px !important;
  }


  .category .sort {
    margin: 0px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .category #example-video {
    width: 600px !important;
    height: 435px !important;
  }

  .category-box {
    max-height: 300px !important;
    overflow-y: auto !important;
  }

  .channelbox {
    max-height: 300px !important;
    overflow-y: auto !important;
  }
/* 
  .imageContainer-movie {
    width: 136px !important;
    height: 152px !important;
  } */

  /* .movie-img {
    max-width: 140px !important;
    max-height: 155px !important;
    min-width: 100px !important;
    min-height: 80px !important;
  } */

  /* .imageContainer-series {
    width: 130px !important;
    height: 145px !important;
  } */

  .series-img {
    max-width: 130px !important;
    max-height: 150px !important;
    min-width: 100px !important;
    min-height: 145px !important;
  }





}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .box {
    min-height: 620px !important;
  }

  .category #example-video {
    width: 445px !important;
    height: 550px !important;
  }

  .category-box {
    max-height: 100vh !important;
    overflow-y: auto !important;
  }

  .channelbox {
    max-height: 100vh !important;
    overflow-y: auto !important;
  }

  /* .imageContainer-movie {
    width: 160px !important;
    height: 238px !important;
  } */

  /* .movie-img {
    max-width: 160px !important;
    max-height: 250px !important;
    min-width: 155px !important;
    min-height: 230px !important;
  }

  .imageContainer-series {
    width: 170px !important;
    height: 230px !important;
  }

  .series-img {
    max-width: 170px !important;
    max-height: 230px !important;
    min-width: 165px !important;
    min-height: 230px !important;
  } */

  /* .carousal-img{    
    min-width: 110px!important;
    max-width: 210px!important;
    min-height: 175px!important;
    max-height: 180px!important;
  } */

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .category .sort {
    margin: 0 15px 0 15px !important;
  }

  .category #example-video {
    width: 680px !important;
    height: 500px !important;
  }

  .category-box {
    max-height: 100vh !important;
    overflow-y: auto !important;
  }

  .channelbox {
    max-height: 100vh !important;
    overflow-y: auto !important;
  }

  /* .imageContainer-movie {
    width: 210px !important;
    height: 250px !important;
  } */

  /* .movie-img {

    max-width: 210px !important;
    max-height: 250px !important;
    min-width: 180px !important;
    min-height: 230px !important;
  }

  .imageContainer-series {
    width: 210px !important;
    height: 230px !important;
  }

  .series-img {
    max-width: 210px !important;
    max-height: 230px !important;
    min-width: 180px !important;
    min-height: 230px !important;

  } */

  /* .carousal-img{    
    min-width: 110px!important;
    max-width: 210px!important;
    min-height: 175px!important;
    max-height: 180px!important;
  } */
}

@media (min-width:401px) and (max-width:570px){
  .home-card {
    /* width: 100% !important; */
    max-width: 150px !important;
    min-height: 132px !important;
    min-width: 135px !important;
    width: 134px !important;
    height: 133px !important;
    background-size: cover;
    cursor: pointer;
    border-radius: 10px;
}
.home-responsive
{
  margin-bottom: 0px !important;
}
.movieInfo{
  padding-left: 0px !important;
}
}
@media (min-width:320px) and (max-width:400px){
  .home-card {
    /* width: 100% !important; */
    max-width: 150px !important;
    min-height: 132px !important;
    min-width: 120px !important;
    width: 120px !important;
    height: 133px !important;
    background-size: cover;
    cursor: pointer;
    border-radius: 10px;
}
.home-responsive
{
  margin-bottom: 10px !important;
}
.img-contain {
  max-width: 340px !important;
  min-width: 235px !important;
  max-height: 270px !important;
  min-height: 255px !important;
}
.info-img { max-width: 340px !important; min-width: 203px !important; max-height: 270px !important; min-height: 255px !important; }
.movieinfoBackground::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, #000103 45%, rgba(0, 1, 3, 0) 100%) !important;
}
.info-plot{
  margin-top: 5rem !important;
}
.like-heart {
  position: absolute;
  margin-left: -38px;
  margin-top: -35px !important;
}
.p-res{
padding-left: 0px !important;
}
.container-fluid{
  margin-left: 0px !important;
  /* padding-left: 0px !important; */
}
.button-info {
  border-radius: 8px;
  font-size: 12px !important;
}
}
@media (min-width:320px) and (max-width:371px){
  .home-card {
    /* width: 100% !important; */
    max-width: 70px !important;
    min-height: 75px !important;
    min-width: 94px !important;
    width: 116px !important;
    height: 93px !important;
    background-size: cover;
    cursor: pointer;
    border-radius: 10px;
}
.home-responsive
{
  /* margin-bottom: 10px !important; */
  margin-top: 5px;
}
.movieInfo{
  padding-left: 0px !important;
}
}