/* You can add global styles to this file, and also import other style files */
@import '~ngx-toastr/toastr.css';
@import './styles/main.css';

.custom-slider .slick-track {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
/* @media(min-width:700px){ */
  .vjs-control-bar {
    height: 50px !important;
    display: flex !important;
    align-items: center !important;
    /* background-color: red !important; */
  }
  .vjs-icon-placeholder{
  font-size: 0.95rem !important;
  }
  .vjs-progress-holder {
    height: 10px;
  }
  .vjs-time-control{
    font-size: 1rem !important;
  }
  .vjs-control , .vjs-button{
    font-size: 1rem !important;
  }
  
  .vjs-remaining-time > span[aria-hidden="true"] {
    display: none !important;
  /* } */
}