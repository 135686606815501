.auth-background {
  height: 100vh;
  /* background: linear-gradient(89.85deg, #23252F 38.36%, rgba(35, 37, 47, 0.6) 97.77%) no-repeat; */
  background: #1B0A37;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.logo a {
  display: inline-block;
  width: 310px;
  height: 48px;
  background: url(/assets/images/Group1.png) left top no-repeat;
  border: 0;
  text-indent: -999999px;
  font-family: Aclonica;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 41px;
  background-size: contain;
}

.login-btn {
  background: #7E24FF;
  border: 0px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 6px 35px 6px 35px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
  color: #FFFFFF;
  outline: none !important;
  min-width: 140px;
}


.login-code {
  outline: none !important;
  padding: 6px 35px 6px 35px;
  background: #49515D;
  border: 0px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 4px 5px 7px rgba(0, 0, 0, 0.25), inset 4px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  position: relative;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
  color: #FFFFFF;
  min-width: 200px;
}

.login-label {
  margin-top: 45px;
  font-size: 30px;
}

.append-white {
  min-width: 30px !important;
}

.responsive-font {
  font-size: calc(8px + 1vw) !important;
}

.text-size {
  font-size: 20px;
}

.auth .form-control {
  background: #2B303C !important;
  border: 2px solid transparent !important;
  color: white !important;
  box-shadow: none;
  border-radius: 8px;
  padding: 0px 38px;
}

.auth .form-control:focus {
  border: 2px solid #7123F6 !important;
  box-shadow: 0px 0px 10px 4px rgba(113, 35, 246, 0.5);
}

.auth .form-control-selector {
  background: #2B303C !important;
  border: 2px solid transparent !important;
  min-width: 195px;
  color: white !important;
  box-shadow: none;
  border-radius: 8px;
  padding: 9px 13px;
  width: 100%;
}

.auth .form-control-selector:focus {
  border: 2px solid #7123F6 !important;
  box-shadow: 0px 0px 10px 4px rgba(113, 35, 246, 0.5);
}

.auth .form-control-pass {
  background: #2B303C !important;
  border: 2px solid transparent !important;
  width: 100%;
  color: white !important;
  /* box-shadow: 0px 0px 10px 4px rgba(113, 35, 246, 0.5); */
  border-radius: 8px;
  padding: 6px 38px;
  outline: none;
}

.auth .form-control-pass:focus {
  border: 2px solid #7123F6 !important;
  box-shadow: 0px 0px 10px 4px rgba(113, 35, 246, 0.5);
}

.pass {
  max-width: 436px !important;
}

.append-white .input-group-text {
  color: #FFFF !important;
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid #FFFFFF !important;
}

.form-group {
  position: relative;
}

.form-group .input-icon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width:1200px){
  .auth-background {
    height: 150vh;}
}

@media (min-width:1000px) and (max-width:1400px) {
  .login-code {
    outline: none !important;
    /* padding: 6px 35px 6px 35px; */
  padding: 0px !important;

    background: #49515D;
    border: 0px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 4px 5px 7px rgba(0, 0, 0, 0.25), inset 4px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    position: relative;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    color: #FFFFFF;
    min-width: 135px;
    margin-left: -22px;
}
.login-btn {
  background: #7E24FF;
  border: 0px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 0px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
  color: #FFFFFF;
  outline: none !important;
  min-width: 115px;
  margin-left: -12px;
}
}