body {
  /* height: 700px; */
  /* background: url('./assets/images/bg1.png') no-repeat 62% 90%; */
  background: #0D0D0D;
  /* background: #1B0A37; */
  color: #FFFFFF;

  /* opacity: 0.88; */
  background-size: cover !important;
  background-repeat: no-repeat;
  /* text-align: center; */
  font-family: 'Roboto', sans-serif;
  --purple: #7E24FF;
  --header-color: #252525;
}
.dark-theme{
  background: #0D0D0D;
  color:white;

}
.light-theme{
  background: white;
  color: black;
}

.text-purple {
  color: var(--purple);
  /* color: #ffffff !important; */
}

.text-black {
  color: black !important;
}

.text-header-backClr {
  background: var(--header-color);
}


a:hover {
  color: var(--purple);
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-35 {
  font-size: 35px !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.d-flex-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.not-clickable {
  pointer-events: none;
}

.header-label {
  color: #FFFFFF;
  font-size: 35px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: var(--purple) !important;
  background-color: var(--purple) !important;
}

.custom-control-label {
  padding-left: 8px;
  padding-top: 1px;
  font-size: 20px;
}

.custom-control-label::after,
.custom-control-label::before {
  width: 21px !important;
  height: 21px !important;
}

.error-msg {
  color: rgb(245, 46, 46);
  font-size: 16px;
}

.cursor-pointer {
  cursor: pointer;
}


/* 
.slick-slider
.slick-prev {
  left: -12px !important;
} */




.slick-slider .slick-next {
  right: -1px !important;
}

app-home .slick-slider .slick-next {
  right: -15px !important;
}


.badge-secondary {
  background-color: #252525 !important;
}


.imageContainer-search {
  /* padding-right: 20px; */
  padding-right: 12px;
  position: relative;
}

.search-img {
  width: 100%;
  height: 100%;
  /* max-height: 230px; */
  max-height: 355px;
  min-height: 220px;
  object-fit: cover;
}

.search-title {
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  max-width: 140px;
}

.fav-icon {
  position: absolute;
  margin-right: 22px;
  bottom: 0;
  right: 0;
  font-size: 22px;
}

.has-search .form-control {
  padding-left: 2.375rem;
  background: #252525;
  color: #ffffff;
  width: 100%;
  outline: 0;
  border: none;
}

.searchclear {
  position: absolute;
  display: block;
  right: 10px;
  top: 0;
  height: 15px;
  margin: auto;
  font-size: 15px;
  cursor: pointer;
  color: #ccc;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.nav-tabs .nav-link.active {
  color: #FFFFFF;
  background-color: #252525;
  border-color: #7E24FF
}

.nav-tabs .nav-link:hover {
  border-color: #7E24FF
}

.nav-tabs {
  border-bottom: 1px solid #7E24FF;
}

.plyr__video-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plyr__video-wrapper video {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}

.player-contain {
  max-width: calc(100vw - 270px);
  max-height: calc(100vh - 110px);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

}

.player-video {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}

.btn-purple {
  background-color: var(--purple) !important;
  color: white !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

@media (min-width:280px) and (max-width:768px){

.player-contain{
  max-width: calc(100vh - 80px) !important;
  max-height: calc(100vh - 110px);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
}